import qs from 'query-string';
import createHistory from 'history/createBrowserHistory';
import decode from 'jwt-decode';
import { Auth } from 'aws-amplify';

import { parseUrl } from '../utils';
import {
  AD_INVESTIGATOR_URL_PATTERN,
  ADS_TRACER_URL_PATTERN,
  ADS_TRACER,
  AD_INVESTIGATOR,
  NEW_TEST_CASE,
  NEW_TEST_CASE_URL_PATTERN,
  DURATION_MANAGER_PATH,
  DURATION_MANAGER_URL_PATTERN,
  DURATION_MANAGER_RULE_INFO_PATH_PREFIX,
  DURATION_MANAGER_RULE_INFO_CHANNEL_URL_PATTERN,
  DURATION_MANAGER_RULE_INFO_SERIES_URL_PATTERN,
  DURATION_MANAGER_RULE_INFO_LEAGUES_URL_PATTERN,
  DURATION_MANAGER_RULE_INFO_SPORTS_URL_PATTERN,
  DURATION_MANAGER_RULE_INFO_EVENTS_URL_PATTERN,
  DURATION_MANAGER_CREATE_RULE_PATH,
  DURATION_MANAGER_CREATE_RULE_URL_PATTERN,
  UP_CREATIVE_TRACKER,
  CREATIVE_TRACKER_URL_PATTERN,
  VAST_TRACKER,
  VAST_TRACKER_URL_PATTERN,
  RESERVATION_TOOL,
  RESERVATION_TOOL_URL_PATTERN,
  DURATION_MANAGER,
  AUCTION_SNAPSHOT,
  AUCTION_SNAPSHOT_URL_PATTERN,
  Q_CHECK,
  Q_CHECK_URL_PATTERN,
  CONFIG_MANAGER,
  CONFIG_MANAGER_URL_PATTERN,
  CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX,
  CONFIG_MANAGER_SOURCE_DETAILS_URL_PATTERN,
  CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX,
  CONFIG_MANAGER_CREATE_SOURCE_DETAILS_URL_PATTERN,
  AUCTION_SNAPSHOT_DETAILED_VIEW,
  AUCTION_SNAPSHOT_DETAILED_VIEW_URL_PATTERN,
} from '../constants';
import { defineRules, isUserContentPartner } from './abilities';
import { sendFeedback, signUp } from '../services/zendesk';

const initState = {
  pathParams: {},
  searchParams: {},
  currentUser: {
    givenName: '',
    familyName: '',
    email: '',
    permissions: null,
  },
  selectedTab: null,
  authenticated: false,
  ticketId: null,
};

const history = createHistory();

export const searchParamsSelector = (state) => state.app.searchParams;
export const pathParamsSelector = (state) => state.app.pathParams;
export const getSelectedTabSelector = (state) => state.app.selectedTab;
export const currentUserSelector = (state) => state.app.currentUser;

export const app = {
  namespace: 'app',
  state: initState,
  reducers: {
    updateCurrentUser(state, { payload: { givenName, familyName, email, permissions } }) {
      const currentUser = { ...state.currentUser, givenName, familyName, email, permissions };
      return { ...state, currentUser, authenticated: true };
    },
    updatePathParams(state, { payload: pathParams }) {
      return { ...state, pathParams };
    },
    updateSearchParams(state, { payload: searchParams }) {
      return { ...state, searchParams };
    },
    updateSelectedTab(state, { payload: selectedTab, permissions }) {
      console.log(selectedTab);
      return { ...state, selectedTab };
    },
    updateTicketId(state, { payload: ticketId }) {
      return { ...state, ticketId };
    },
    resetState() {
      return { ...initState };
    },
  },
  effects: {
    *pageInit({ payload: fetchData }, { call, put }) {
      let auth = yield put({ type: 'checkAuth' });
      let loggedIn = yield auth.then((res) => !!res.payload?.permissions);
      if (loggedIn) {
        yield call(fetchData);
      }
    },
    *checkAuth({ payload: isSignIn }, { call, put }) {
      try {
        const userInfo = yield isSignIn ? Auth.federatedSignIn() : Auth.currentAuthenticatedUser();
        if (userInfo && userInfo?.attributes) {
          const { given_name: givenName, family_name: familyName, email } = userInfo.attributes;

          // To continue work on
          const session = yield call(() => Auth.currentSession());
          const { user_claims: usrClaimsStr } = decode(session.getIdToken().getJwtToken());
          const usrClaimObj = JSON.parse(usrClaimsStr);
          const permissions = defineRules(usrClaimObj);

          return yield put({
            type: 'updateCurrentUser',
            payload: {
              givenName,
              familyName,
              email,
              permissions,
            },
          });
        }
      } catch (error) {
        console.warn('User not logged in, going through login flow: ' + error);
      }
      return {};
    },
    *setSearchParams({ payload: searchParams }, { put }) {
      yield put({ type: 'updateSearchParams', payload: searchParams });
      history.push({ search: qs.stringify(searchParams), pathname: window.location.pathname });
    },
    *logout(emptyPayload, { put }) {
      yield Auth.signOut();
      yield put({ type: 'resetState' });
    },
    *signUp({ payload: values }, { call, put }) {
      yield put({ type: 'updateTicketId', payload: null });
      const resp = yield call(signUp, values);

      if (resp?.status === 201 && resp?.data?.ticket) {
        yield put({ type: 'updateTicketId', payload: resp.data.ticket.id });
      }
    },
    *sendFeedback({ payload: values }, { call, put }) {
      yield put({ type: 'updateTicketId', payload: null });
      const resp = yield call(sendFeedback, values);

      if (resp?.status === 201 && resp?.data?.ticket) {
        yield put({ type: 'updateTicketId', payload: resp.data.ticket.id });
      }
    },
    *closeTicketAlert(_, { put }) {
      yield put({ type: 'updateTicketId', payload: null });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname, search }) => {
        let params = { pathParams: {}, searchParams: {} };
        let model = '';
        let pattern = '';

        // Ads Tracer: View Test Cases Flow
        if (pathname.startsWith(`/${ADS_TRACER}`)) {
          model = 'adsTracer';
          pattern = ADS_TRACER_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: ADS_TRACER });

          // Ads Tracer: New Test Case Flow
        } else if (pathname.startsWith(`/${NEW_TEST_CASE}`)) {
          model = 'newTestCase';
          pattern = NEW_TEST_CASE_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: ADS_TRACER });

          // Ad Investigator
        } else if (pathname.startsWith(`/${AD_INVESTIGATOR}`)) {
          model = 'adInvestigator';
          pattern = AD_INVESTIGATOR_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: AD_INVESTIGATOR });

          // Duration Manager
          //form display based on ruletype
        } else if (pathname.startsWith(DURATION_MANAGER_RULE_INFO_PATH_PREFIX) && pathname.includes('series')) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_RULE_INFO_SERIES_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_RULE_INFO_PATH_PREFIX) && pathname.includes('leagues')) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_RULE_INFO_LEAGUES_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_RULE_INFO_PATH_PREFIX) && pathname.includes('sports')) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_RULE_INFO_SPORTS_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_RULE_INFO_PATH_PREFIX) && pathname.includes('event')) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_RULE_INFO_EVENTS_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_RULE_INFO_PATH_PREFIX)) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_RULE_INFO_CHANNEL_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_CREATE_RULE_PATH)) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_CREATE_RULE_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });
        } else if (pathname.startsWith(DURATION_MANAGER_PATH)) {
          model = 'durationManager';
          pattern = DURATION_MANAGER_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: DURATION_MANAGER });

          // Creative Tracker
        } else if (pathname.startsWith(`/${UP_CREATIVE_TRACKER}`)) {
          model = 'creativeTracker';
          pattern = CREATIVE_TRACKER_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: UP_CREATIVE_TRACKER });

          // Vast Tracker
        } else if (pathname.startsWith(`/${VAST_TRACKER}`)) {
          model = 'vastTracker';
          pattern = VAST_TRACKER_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: VAST_TRACKER });

          // Reservation Tool
        } else if (pathname.startsWith(`/${RESERVATION_TOOL}`)) {
          model = 'reservationTool';
          pattern = RESERVATION_TOOL_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: RESERVATION_TOOL });

          // Q-Check
        } else if (pathname.startsWith(`/${Q_CHECK}`)) {
          model = 'qCheck';
          pattern = Q_CHECK_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: Q_CHECK });

          // Config Manager
        } else if (pathname.startsWith(CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX)) {
          model = 'configManager';
          pattern = CONFIG_MANAGER_SOURCE_DETAILS_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: CONFIG_MANAGER });
        } else if (pathname.startsWith(CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX)) {
          model = 'configManager';
          pattern = CONFIG_MANAGER_CREATE_SOURCE_DETAILS_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: CONFIG_MANAGER });
        } else if (pathname.startsWith(`/${CONFIG_MANAGER}`)) {
          model = 'configManager';
          pattern = CONFIG_MANAGER_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: CONFIG_MANAGER });

          // Auction Snapshot
        } else if (pathname.startsWith(`/${AUCTION_SNAPSHOT}`) && !pathname.includes(AUCTION_SNAPSHOT_DETAILED_VIEW)) {
          model = 'auctionSnapshot';
          pattern = AUCTION_SNAPSHOT_URL_PATTERN;
          dispatch({ type: 'updateSelectedTab', payload: AUCTION_SNAPSHOT });
        } else {
          dispatch({ type: 'updateSelectedTab', payload: null });
        }

        params = parseUrl(pathname, pattern, search);
        const { pathParams, searchParams } = params;
        dispatch({ type: 'updatePathParams', payload: pathParams });
        dispatch({ type: 'updateSearchParams', payload: searchParams });
        dispatch({
          type: 'pageInit',
          payload: () => dispatch({ type: `${model}/pageInit`, payload: params }),
        });
      });
    },
  },
};
